<template>
    <v-card flat>
        <v-app-bar flat :dark="dark" :color="color" v-if="toolbar" dense>
            <v-toolbar-title class="font-weight-light" v-if="cardTitle">
                {{cardTitle}}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu offset-y bottom left v-if="showMenu">
                <template v-slot:activator="{ on }">
                    <v-btn icon fab small v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list dense class="py-0">
                    <v-list-item @click="$emit('update:dialogResetPassword', true)">
                        <v-list-item-title>Mot de passe oublié</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('update:dialogRemoveAccount', true)">
                        <v-list-item-title>Supprimer le compte</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('update:dialogForceValidationAndRestore', true)">
                        <v-list-item-title>Forcer la validation</v-list-item-title>
                    </v-list-item>
                </v-list>

            </v-menu>
        </v-app-bar>

        <v-list :three-line="!!userRaw.mobile_number" :two-line="!userRaw.mobile_number"
                class="transparent py-0 ">
            <v-list-item>
                <v-list-item-avatar
                        @click="uploadAvatar"
                        class="my-3 white--text clickable justify-center"
                        id="avatarElement"
                        :color="user.avatar && user.avatar.data.url ? null : 'primary'">
                    <img v-if="user.avatar && user.avatar.data"
                         v-media-src="user.avatar.data">
                    <template v-else>
                        {{user && user.username ? user.username.substr(0, 2).toUpperCase() : ''}}
                    </template>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                        {{userRaw.first_name}} {{userRaw.last_name}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <a :href="`tel:${userRaw.mobile_number}`"
                           class="default--text">
                            {{userRaw.mobile_number}}
                        </a>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        <a :href="`mailto:${userRaw.email}`"
                           class="default--text">
                            {{userRaw.email}} <v-badge v-if="user.deleted_at" color="error" content="Compte Supprimé" inline ></v-badge>
                        </a>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="userRaw.last_login_at">
                        Dernière connexion : {{ $helpers.parseDate(userRaw.last_login_at, 'DD/MM/YYYY HH:mm') }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="userRaw.created_at">
                        Inscrit le : {{ $helpers.parseDate(userRaw.created_at, 'DD/MM/YYYY HH:mm') }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        Provenance : <span v-if="user.from_resource_register" class="font-weight-bold">Ressource</span> <span v-else-if="user.is_from_webhook && !user.is_direct_customer" class="font-weight-bold">Cours Gratuit</span> <span v-else-if="user.is_direct_customer" class="font-weight-bold">Client direct</span> <span v-else class="font-weight-bold">Naturel</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        Est actif sur MyGuitare : <b>{{ (user.is_active_on_mg ? 'Oui' : 'Non') }}</b> | Est actif sur MyPianoPop : <b>{{ (user.is_active_on_mpp ? 'Oui' : 'Non') }}</b>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        Date premier achat MyGuitare : <b>{{ (user.mg_first_buy_date ? $helpers.parseDate(user.mg_first_buy_date, 'DD/MM/YYYY HH:mm') : '--') }}</b> | Date premier achat MyPianoPop : <b>{{ (user.mpp_first_buy_date ? $helpers.parseDate(user.mpp_first_buy_date, 'DD/MM/YYYY HH:mm') : '--') }}</b>
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                small
                                depressed
                                v-bind="attrs"
                                v-on="on"
                                @click="autoLoginDialog = true; autoLoginEmail = user.email"
                                target="_blank"
                                color="primary">
                                <v-icon dark>
                                    mdi-login
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Se connecter en tant que {{ user.email }}</span>
                    </v-tooltip>
                </v-list-item-icon>

                <v-list-item-action>
                    <v-menu offset-y
                            v-if="canDelete"
                            left
                            bottm
                            small
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn icon
                                   v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list dense class="py-0">
                            <template v-if="canDelete">
                                <v-list-item @click="openDeleteConfirmation">
                                    <v-list-item-title class="primary--text">
                                        Supprimer
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </v-list-item-action>
            </v-list-item>
        </v-list>


        <mg-alert ref="mgAlert"></mg-alert>

        <v-card-text>
            <v-container grid-list-md class="pa-0 container--fluid">

                <v-row>
                    <v-col v-if="creation" cols="12" md="12">
                            <v-card-text class="pb-0">
                                Merci de choisir une plateforme pour la création de l'utilisateur. Le compte créé lui permettra bien d'accèder à l'ensemble des plateformes, le choix ici sert uniquement à envoyer le bon mail.
                            </v-card-text>
                            <v-select
                                v-model="user.platform"
                                :items="['MYPIANOPOP', 'MYGUITARE']"
                                label="Plateforme"
                                persistent-hint
                                return-object
                                single-line
                            ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-validate="'required'"
                                v-model="user.username"
                                :error-messages="errors.collect('username')"
                                label="Pseudo *"
                                data-vv-name="username"
                                required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-validate="'required|email'"
                                v-model="user.email"
                                :error-messages="errors.collect('email')"
                                label="Email *"
                                data-vv-name="email"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-validate
                                v-model="user.last_name"
                                :error-messages="errors.collect('last_name')"
                                label="Nom"
                                data-vv-name="last_name"
                                required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-validate
                                v-model="user.first_name"
                                :error-messages="errors.collect('first_name')"
                                label="Prénom"
                                data-vv-name="first_name"
                                required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                mask="## ## ## ## ##"
                                v-model="user.mobile_number"
                                v-validate="'digits:10'"
                                :error-messages="errors.collect('mobile_number')"
                                label="Téléphone"
                                data-vv-name="mobile_number"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <mg-datepicker
                                label="Date de naissance"
                                v-model="user.birthday"
                                v-validate
                                :max="new Date().toISOString()"
                                data-vv-name="birthday">
                        </mg-datepicker>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                                v-model="user.gender"
                                :items="genderValues"
                                v-validate
                                :error-messages="errors.collect('gender')"
                                data-vv-name="gender"
                                label="Sexe"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                                v-model="userInstruments"
                                :items="instrumentValues"
                                v-validate
                                multiple
                                item-text="name"
                                item-value="id"
                                :error-messages="errors.collect('gender')"
                                data-vv-name="userInstruments"
                                label="Instrument(s) préféré(s)"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="6" class="mt-0 pt-2">
                        <mg-google-autocomplete
                                id="autocomplete"
                                :value="userAddress.formatted_address"
                                label="Adresse"
                                country="fr"
                                :error-messages="addressFormErrors"
                                append-icon="mdi-dots-vertical"
                                @click:append="() => {this.dialog = true}"
                                @placechanged="getAddressData">
                        </mg-google-autocomplete>

                        <v-dialog v-model="dialog" eager persistent max-width="800px">
                            <user-address-form
                                    ref="userAddressForm"
                                    v-model="userAddress"
                                    toolbar
                                    :isFormDirty.sync="userAddressFormIsDirty"
                                    :dialog.sync="dialog">
                            </user-address-form>
                        </v-dialog>
                    </v-col>

                    <v-col cols="12" md="6" v-if="showRoles && user.roles && user.roles">
                        <v-autocomplete
                                v-model="user.roles.data"
                                :items="roleValues"
                                v-validate
                                :error-messages="errors.collect('roles')"
                                data-vv-name="roles"

                                multiple
                                returnObject
                                :item-text="translateRole"
                                menu-props="bottom, offsetY"
                                label="Role(s)">
                            <template v-slot:selection="{ item }">
                                <v-chip>{{ translateRole(item) }}</v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="userLessonUnlockCount"
                            v-validate="'digits:1'"
                            :error-messages="errors.collect('user_lesson_unlock_count')"
                            label="Nombre restant de lecon déblocable (pour les deux plateformes)"
                            max="3"
                            :items="[0, 1, 2, 3]"
                            min="0"
                            clearable
                            data-vv-name="user_lesson_unlock_count"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-checkbox
                                v-validate
                                v-model="user.is_customer"
                                :error-messages="errors.collect('is_customer')"
                                label="Est un client ? (Permet de forcer le status afin de débloquer le forum / carte)"
                                data-vv-name="is_customer"
                                :true-value="true"
                                :false-value="false"
                                @change="fields.is_customer.dirty = true"
                                color="secondary"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Bloquer l'accès au site
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Le user ne pourra plus se connecter
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-checkbox
                                v-validate
                                v-model="user.is_disabled"
                                :error-messages="errors.collect('is_disabled')"
                                label="Oui"
                                data-vv-name="is_disabled"
                                :true-value="true"
                                :false-value="false"
                                @change="fields.is_disabled.dirty = true"
                                color="secondary"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <mg-datepicker
                                :label="user.is_disabled ? 'À désactiver le' : 'À activer le'"
                                v-model="user.toggle_is_disabled_at"
                                v-validate
                                persistent-hint
                                :hint="'Aucune date = ne s\'inverse jamais'"
                                :min="new Date().toISOString()"
                                data-vv-name="toggle_is_disabled_at">
                        </mg-datepicker>
                    </v-col>

                    <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Bloquer l'accès illimité aux cours
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Le user ne pourra plus accéder de façon illimité aux cours
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-checkbox
                            v-validate
                            v-model="user.force_disable_unlimited_access"
                            :error-messages="errors.collect('force_disable_unlimited_access')"
                            label="Oui"
                            data-vv-name="force_disable_unlimited_access"
                            :true-value="true"
                            :false-value="false"
                            @change="fields.force_disable_unlimited_access.dirty = true"
                            color="secondary"
                        ></v-checkbox>
                    </v-col>


                    <v-col cols="12" sm="6">
                        <mg-datepicker
                            :label="user.force_disable_unlimited_access ? 'À désactiver le' : 'À activer le'"
                            v-model="user.toggle_force_disable_unlimited_access_at"
                            v-validate
                            persistent-hint
                            :hint="'Aucune date = ne s\'inverse jamais'"
                            :min="new Date().toISOString()"
                            data-vv-name="toggle_force_disable_unlimited_access_at">
                        </mg-datepicker>
                    </v-col>

                    <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Abonnement VIP global
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Le user pourra utiliser les feedbakcs sur tous ses cours
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-checkbox
                                v-validate
                                v-model="user.has_vip_subscription"
                                :error-messages="errors.collect('has_vip_subscription')"
                                label="Oui"
                                data-vv-name="has_vip_subscription"
                                :true-value="true"
                                :false-value="false"
                                @change="fields.has_vip_subscription.dirty = true"
                                color="secondary"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <mg-datepicker
                                :label="user.has_vip_subscription ? 'À désactiver le' : 'À activer le'"
                                v-model="user.toggle_has_vip_subscription_at"
                                v-validate
                                persistent-hint
                                :hint="'Aucune date = ne s\'inverse jamais'"
                                :min="new Date().toISOString()"
                                data-vv-name="toggle_has_vip_subscription_at">
                        </mg-datepicker>
                    </v-col>

                    <!-- <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Abonnement Club
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Vous permet d'activer l'abonnement Club sur le tier que vous souhaitez pour cet utilisateur. Vous pouvez également fournir une date à laquelle le cet avantage se terminera.
                            </v-card-text>
                            <v-card-text class="pb-0 red--text">
                                Attention, ici n'est pas affiché si un utilisateur à déjà payé pour un abonnement "Club". Vous pouvez le voir sur l'onglet "Souscriptions".
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="3">
                        <v-select
                            v-model="clubTier"
                            :items="clubTiers"
                            item-text="text"
                            item-value="id"
                            label="Select"
                            persistent-hint
                            return-object
                            single-line
                            data-vv-name="force_club_tier"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" sm="3">
                        <v-select
                            v-model="clubPlatform"
                            :items="clubPlatforms"
                            item-text="text"
                            item-value="id"
                            label="Select"
                            persistent-hint
                            return-object
                            single-line
                            data-vv-name="force_club_platform"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <mg-datepicker
                                label="À désactiver le"
                                v-model="user.deactivate_force_club_at"
                                v-validate
                                persistent-hint
                                :hint="'Aucune date = ne se désactive jamais'"
                                :min="new Date().toISOString()"
                                data-vv-name="deactivate_force_club_at">
                        </mg-datepicker>
                    </v-col> -->

                    <v-col cols="12" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-card-title class="pb-0 title font-weight-bold">
                                Accès au téléchargement des vidéos
                            </v-card-title>
                            <v-card-text class="pb-0">
                                Le user pourra télécharger toutes les vidéos uploadées sur Vimeo de ses cours
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-checkbox
                                v-validate
                                v-model="user.is_allowed_to_download"
                                :error-messages="errors.collect('is_allowed_to_download')"
                                label="Oui"
                                data-vv-name="is_allowed_to_download"
                                :true-value="true"
                                :false-value="false"
                                @change="fields.is_allowed_to_download.dirty = true"
                                color="secondary"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <mg-datepicker
                                :label="user.is_allowed_to_download ? 'À désactiver le' : 'À activer le'"
                                v-model="user.toggle_is_allowed_to_download_at"
                                v-validate
                                persistent-hint
                                :hint="'Aucune date = ne s\'inverse jamais'"
                                :min="new Date().toISOString()"
                                data-vv-name="toggle_is_allowed_to_download_at">
                        </mg-datepicker>
                    </v-col>
                </v-row>
            </v-container>

                <v-alert
                v-if="createError"
                class="mt-2"
                border="top"
                color="red lighten-2"
                dark
                >
                    Impossible de créer l'utilisateur. Il se peut qu'il existe déjà.
                </v-alert>

            <v-card-actions class="justify-center">
                <v-btn :color="color"
                       class="lighten-1"
                       :loading="loading"
                       @click="submit()">{{submitBtn ? submitBtn : 'Enregistrer'}}
                </v-btn>
            </v-card-actions>
        </v-card-text>

        <mg-confirmation-modal :dialog="dialogDelete"
                               @update:dialog="$emit('update:dialogDelete', $event)"
                               @delete="deleteUser"
        ></mg-confirmation-modal>

        <v-dialog
                :value="dialogDeleting"
                @input="$emit('update:dialogDeleting', $event)"
                persistent
                width="300">
            <v-card color="accent" dark>
                <v-card-text>
                    Suppression en cours
                    <v-progress-linear
                            indeterminate
                            color="primary"
                            class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog :value="dialogResetPassword"
                  max-width="600px"
                  color="creamy"
                  @input="$emit('update:dialogResetPassword', $event)">

            <v-card flat tile color="creamy">
                <v-app-bar tile flat dense dark color="secondary">
                    <v-btn icon small fab dark @click="$emit('update:dialogResetPassword', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div class="flex-grow-1"></div>
                </v-app-bar>

                <v-card flat color="creamy">
                    <v-card-title class="title justify-center">
                        Lien permettant de réinitialiser le mot de passe
                    </v-card-title>
                          <v-col>
                            <v-select
                                v-model="resetPlatform"
                                :items="[{id: 'mpp', text: 'MyPianoPop'}, {id: 'mg', text: 'MyGuitare'}]"
                                item-text="text"
                                item-value="id"
                                label="Select"
                                persistent-hint
                                return-object
                                single-line
                            ></v-select>
                            <p class="text-caption">Séléctionner la plateforme avec laquelle vous voulez avoir le lien de résiliation du mot de passe.</p>
                            </v-col>
                    <v-card-text class="text-center pb-5 px-10">
                        <v-card flat class="pa-2 white">

                            {{ customResetLink }}
                        </v-card>
                    </v-card-text>

<!--                    <v-card-actions class="justify-center pb-5">-->
<!--                        <v-btn :color="color"-->
<!--                               class="lighten-1"-->
<!--                               :loading="loading"-->
<!--                               @click="createResetPasswordToken">Rafraichir-->
<!--                        </v-btn>-->
<!--                    </v-card-actions>-->
                </v-card>
            </v-card>
        </v-dialog>

        <v-dialog :value="dialogRemoveAccount"
                  max-width="600px"
                  color="creamy"
                  @input="$emit('update:dialogRemoveAccount', $event)">

            <v-card flat tile color="creamy">
                <v-app-bar tile flat dense dark color="secondary">
                    <v-btn icon small fab dark @click="$emit('update:dialogRemoveAccount', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div class="flex-grow-1"></div>
                </v-app-bar>

                <v-card flat color="creamy px-4">
                    <v-card-title class="title">
                        Confirmation
                    </v-card-title>

                    <v-card-text class="pb-5">
                        Veuillez confirmer la suppression du compte {{ user.email }}.
                    </v-card-text>

                   <v-card-actions class="justify-center pb-5">
                        <v-btn color="error"
                               :loading="loading"
                               @click="removeAccount">Confirmer la suppression
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-dialog>

        <v-dialog :value="dialogForceValidationAndRestore"
                  max-width="600px"
                  color="creamy"
                  @input="$emit('update:dialogForceValidationAndRestore', $event)">

            <v-card flat tile color="creamy">
                <v-app-bar tile flat dense dark color="secondary">
                    <v-btn icon small fab dark @click="$emit('update:dialogForceValidationAndRestore', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div class="flex-grow-1"></div>
                </v-app-bar>

                <v-card flat color="creamy px-4">
                    <v-card-title class="title">
                        Confirmation
                    </v-card-title>

                    <v-card-text class="pb-5">
                        Vous êtes sur le point de forcer la validation de compte pour {{ user.email }}.
                    </v-card-text>

                   <v-card-actions class="justify-center pb-5">
                        <v-btn color="error"
                               :loading="loading"
                               @click="forceValidationAndRestore()">Confirmer l'action
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-dialog>

        <v-dialog v-model="autoLoginDialog" width="500">
            <v-card>
                <v-card-title class="text-center">
                    Se connecter à :
                    <v-btn
                        depressed
                        @click="loginAs('mg')"
                        target="_blank"
                        class="ma-2"
                        color="primary">
                        MyGuitare
                    </v-btn>
                    <v-btn
                        depressed
                        @click="loginAs('mpp')"
                        target="_blank"
                        color="primary">
                        MyPianoPop
                    </v-btn>
                </v-card-title>
            </v-card>

        </v-dialog>
    </v-card>
</template>

<script>
    import _ from 'lodash'
    import Helpers from 'mg-js-core/lib/services/Helpers'
    import InstrumentLogic from 'mg-js-core/lib/logics/InstrumentLogic'
    import parallel from 'async/parallel';
    import moment from 'moment';
    import UserLogic from "mg-js-core/lib/logics/UserLogic";
    import UppyLogic from "mg-js-core/lib/logics/UppyLogic";
    import {EventBus} from "mg-js-core/lib/services/Request";
    import AuthLogic from "mg-js-core/lib/logics/AuthLogic";

    export default {
        name: 'user-profile-form',

        inject: {
            $validator: '$validator'
        },

        props: {
            cardTitle: String,
            color: String,
            submitBtn: String,
            dark: Boolean,
            value: {
                type: Object,
                default() {
                    return UserLogic.getEmptyObject()
                }
            },
            showAs: {
                type: String,
                default: 'member'
            },
            address: Object,
            addressIdToDelete: String,
            addressFormIsDirty: Boolean,
            loading: Boolean,
            instruments: {
                type: Array,
                default() {
                    return []
                }
            },
            resetPasswordLink: String,
            resetToken: String,

            toolbar: Boolean,
            showIsEnabled: Boolean,


            canDelete: Boolean,
            dialogDelete: Boolean,
            dialogDeleting: Boolean,

            showMenu: Boolean,
            dialogResetPassword: Boolean,
            dialogRemoveAccount: Boolean,
            dialogForceValidationAndRestore: Boolean,

            showRoles: Boolean,
            roles: Array,
            creation: Boolean,
            createError: Boolean,
        },

        data: () => ({
            dialog: false,

            instrumentValuesLoading: false,

            date: null,
            userLessonUnlockCount: 0,

            genderValues: [
                {text: 'Homme', value: 'MAN'},
                {text: 'Femme', value: 'WOMAN'},
            ],
            instrumentValues: [],

            pagination: Helpers.initPagination(100, 1, 'slug', 0),

            filters: {
                enabled: 1,
                search: '',
            },

            addressFormErrors: [],

            userRaw: UserLogic.getEmptyObject(),
            uppyLogic: new UppyLogic(),
            resetPlatform: {id: 'mg', text: 'MyGuitare'},

            autoLoginDialog : false,
            autoLoginEmail : null,
            clubTier: null,
            clubPlatform: null,
            clubPlatforms: [{id: null, text: 'Toutes les plateformes'}, {id: 'MYGUITARE', text: 'MYGUITARE'}, {id: 'MYPIANOPOP', text: 'MYPIANOPOP'}],
            clubTiers: [{id: null, text: 'Pas d\'abonnement Club'}, {id: 1, text: 'Tier 1'}, {id: 2, text: 'Tier 2'}, {id: 3, text: 'Tier 3'}],
        }),

        mounted() {
            if (this.value) {
                this.parseUser(this.value);
            }
            this.getAllInstruments()
        },

        computed: {
            user: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                }
            },

            userInstruments: {
                get() {
                    return this.instruments;
                },
                set(value) {
                    this.$emit('update:instruments', value)
                }
            },

            userAddress: {
                get() {
                    return this.address;
                },
                set(value) {
                    this.$emit('update:address', value)
                }
            },

            userAddressFormIsDirty: {
                get() {
                    return this.addressFormIsDirty;
                },
                set(value) {
                    this.$emit('update:addressFormIsDirty', value)
                }
            },

            roleValues: {
                get() {
                    return this.roles;
                },
                set(value) {
                    this.$emit('update:roles', value)
                }
            },

            userAddressFormComponent() {
                return this.$refs.userAddressForm
            },

            isDirty() {
                return Helpers.isFormDirty(this) || this.addressFormIsDirty
            },

            // useful for parent component
            mgAlertComponent() {
                return this.$refs.mgAlert
            },

            customResetLink() {
                const base = (this.resetPlatform.id === 'mg' ? 'myguitare' : 'mypianopop');

                return `https://membre.${base}.com/password/update/${this.resetToken}`;
            }
        },

        watch: {
            value: {
                handler(value) {
                    this.parseUser(value);
                },
                deep: true
            },
            dialog(value) {
                if (!value) {
                    this.$nextTick(() => {
                        this.validateUserAddressForm().then(isValid => {
                            this.addressFormErrors = !isValid
                                ? ["L'adresse est invalide."]
                                : []
                        })
                    })
                }
            },
            clubTier(val) {
                this.user.force_club_tier = val.id;
            },
            clubPlatform(val) {
                this.user.force_club_platform = val.id;
            }
        },

        methods: {
            parseUser(value) {
                this.user = Object.assign(this.user, value);

                if (this.user.birthday) {
                    this.date = moment(this.user.birthday).format('YYYY-MM-DD');
                }

                if (this.user.force_club_tier) {
                    this.clubTier = {id: this.user.force_club_tier, text: 'Tier ' + this.user.force_club_tier};
                }

                if (this.user.force_club_platform) {
                    this.clubPlatform = {id: this.user.force_club_platform, text: this.user.force_club_platform};
                }

                this.refreshRaw()
            },

            getAllInstruments() {
                this.instrumentValuesLoading = true;

                InstrumentLogic
                    .getAll(this.pagination, this.filters)
                    .then(({data, totalItems}) => {
                        this.instrumentValues = data;
                        this.totalItems = totalItems;
                        this.instrumentValuesLoading = false;
                    })
                    .catch((err) => {
                        this.instrumentValuesLoading = false;
                        console.error(err)
                    })

            },

            getAddressData(addressData) {
                if (addressData) {
                    // this.addressIdToDelete = null;
                    this.$emit('update:addressIdToDelete', null)
                    this.userAddress = Object.assign(this.userAddress, addressData);

                    setTimeout(() => {
                        this.validateUserAddressForm().then(isValid => {
                            if (!isValid) {
                                this.addressFormErrors = ["L'adresse est invalide."]
                            }
                        })
                    }, 200);
                } else {
                    if (this.userAddress.id) {
                        this.$emit('update:addressIdToDelete', this.userAddress.id)
                        // this.addressIdToDelete = this.userAddress.id;
                    }
                }
            },

            validateUserProfileForm() {
                return this.$validator.validateAll();
            },

            validateUserAddressForm() {
                return this.userAddressFormComponent.validateAll();
            },

            submit() {
                parallel([
                    (callback) => {
                        this.validateUserProfileForm().then(isValid => {
                            if (isValid) {
                                callback(null, isValid)
                            } else {
                                callback('invalid')
                            }
                        })
                    },
                    (callback) => {
                        if (this.addressFormIsDirty && !this.addressIdToDelete) {
                            this.validateUserAddressForm().then(isValid => {
                                if (isValid) {
                                    callback(null, isValid)
                                } else {
                                    callback('invalid')
                                }
                            })
                        } else {
                            callback()
                        }
                    }
                ], (err) => {
                    if (!err) {
                        let body = {};

                        _.forIn(this.fields, (value, key) => {
                            if (value.dirty && key !== 'roles') {
                                body[key] = this.user[key]
                            } else if (value.dirty && key === 'roles') {
                                body[key] = this.user.roles.data
                                    ? _.map(this.user.roles.data, item => item.name)
                                    : []
                            }
                        });

                        body['force_club_platform'] = this.user.force_club_platform;
                        body['force_club_tier'] = this.user.force_club_tier;
                        body['is_customer'] = this.user.is_customer;

                        if(this.fields['user_lesson_unlock_count'].dirty) {
                            body.user_lesson_unlock_count = this.userLessonUnlockCount ? this.userLessonUnlockCount : 0;
                        }

                        this.$emit('submit', body);
                    }
                });
            },


            refreshRaw() {
                this.userRaw = Object.assign({}, this.user)
            },


            uploadAvatar() {
                const params = {
                    url: null,
                    fieldName: 'avatar',
                };

                if (this.showAs === 'admin') {
                    params.url = `/users/${this.user.id}`;
                } else if (this.showAs === 'member') {
                    params.url = `/me`;
                }

                this.uppyLogic.openPopup(params).then(() => {
                    EventBus.$emit('refresh-user');
                });
            },

            openDeleteConfirmation() {
                this.$emit('update:dialogDelete', true)
            },

            deleteUser() {
                this.$emit('update:dialogDelete', false)
                this.$emit('update:dialogDeleting', true)

                this.$emit('deleteUser', this.user)
            },

            createResetPasswordToken() {
                this.$emit('createResetPasswordToken')
            },

            removeAccount() {
                this.$emit('removeAccount')
            },

            forceValidationAndRestore() {
                this.$emit('forceValidationAndRestore')
            },

            removeInstrument(item) {
                const index = this.userInstruments.indexOf(item.id)
                this.fields['userInstruments'].dirty = true;
                if (index >= 0) this.userInstruments.splice(index, 1)
            },


            translateRole(role) {
                const roleValues = [
                    {text: 'Admin', value: 'admin'},
                    {text: 'Membre', value: 'member'},
                    {text: 'Professeur', value: 'teacher'},
                    {text: 'Beta testeur', value: 'beta-tester'},
                    {text: 'Statistiques', value: 'statistic'},
                    {text: 'Statistiques avancé', value: 'statistic-advanced'},
                    {text: 'Support', value: 'support'},
                    {text: 'Marketing', value: 'marketing'},
                    {text: 'Super admin', value: 'super-admin'},
                ];

                const roleName = _.find(roleValues, {value: role.name});

                return roleName ? roleName.text : '-'
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors)

            },

            loginAs(front) {
                let redirectUrl = front === 'mpp'
                    ? process.env.VUE_APP_MPP_FRONT_HOST
                    : process.env.VUE_APP_MG_FRONT_HOST
                    console.log(redirectUrl);

                AuthLogic
                    .getLoginToken({email: this.autoLoginEmail})
                    .then((result) => {
                        this.autoLoginDialog = false;
                        this.autoLoginEmail = null;
                        window.open(`${redirectUrl}/login-admin?token=${result}`, '_blank')
                    })
            },
        }
    }
</script>
