<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>

        <template v-if="!isFullAccessProduct">
            <v-stepper v-model="stepperIndex" vertical class="transparent">
                <v-stepper-step :complete="stepperIndex > 1" :step="1" editable>
                    Abonnement
                </v-stepper-step>

                <v-stepper-content step="1">
                    <plan-form
                            dark
                            :canEditStripeId="canEditStripeId"
                            ref="currentPlanForm"
                            submitBtn="Continuer"
                            submitBtnColor="secondary lighten-1"
                            @submit="stepperIndex = 2"
                            showVip
                            :product.sync="product"
                            v-model="currentPlan">
                    </plan-form>
                </v-stepper-content>


                <v-stepper-step :complete="stepperIndex > 2" :step="2">
                    Modules
                </v-stepper-step>

                <v-stepper-content step="2">
                    <v-list two-line subheader v-for="selectedCourse in selectedCourses" :key="selectedCourse.id">
                        <v-subheader>{{selectedCourse.title}}</v-subheader>

                        <template v-if="selectedCourse.courseModules">
                            <v-list-item v-for="courseModule in selectedCourse.courseModules.data" :key="courseModule.id"
                                         class="clickable">
                                <v-list-item-action>
                                    <v-checkbox v-model="courseModulesSelectedValue"
                                                color="secondary"
                                                @change="$emit('change', $event)"
                                                :ref="courseModule.id"
                                                :value="courseModule.id"></v-checkbox>
                                </v-list-item-action>

                                <v-list-item-content @click="selectCourseModule(courseModule)">
                                    <v-list-item-title>{{courseModule.title}}</v-list-item-title>

                                    <v-list-item-subtitle>
                                        <template v-if="courseModule.lessons && courseModule.lessons.data">
                                            {{courseModule.lessons.data.length}} leçon<span
                                                v-if="courseModule.lessons.data.length > 1">s</span>
                                        </template>
                                        <template v-else>
                                            0 leçon
                                        </template>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>

                    </v-list>

                    <div class="text-center">
                        <v-btn color="secondary lighten-1"
                               :loading="loading"
                               @click="submit">Enregistrer
                        </v-btn>
                    </div>
                </v-stepper-content>
            </v-stepper>
        </template>
        <template v-else>
            <plan-form
                    dark
                    class="mx-4"
                    ref="currentPlanForm"
                    submitBtn="Continuer"
                    submitBtnColor="secondary lighten-1"
                    @submit="submit"
                    showVip
                    :isLoading="loading"
                    :product.sync="product"
                    v-model="currentPlan">
            </plan-form>
        </template>

    </v-card>
</template>

<script>
    import _ from 'lodash'
    import Helpers from "mg-js-core/lib/services/Helpers";
    import ProductLogic from "mg-js-core/lib/logics/ProductLogic";

    export default {
        name: 'plan-create-stepper',

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            dark: Boolean,
            showToolbar: String,
            toolbarTitle: String,
            dialog: Boolean,
            isDialog: Boolean,

            value: {
                type: Object,
                required: true,
            },
            canEditStripeId: {
                type: Boolean,
                default: false
            },
            product: {
                type: Object,
                required: true,
            },
            loading: Boolean,
            step: {},
            selectedCourses: Array,

            showSelectCourses: Boolean,
            availableProducts: Array,

            courseModulesSelected: Array,
        },

        data: () => ({
            rowActive: {},
            // selectedCourses: []
        }),

        mounted() {
            this.syncPlan()
        },

        computed: {
            isFullAccessProduct() {
                return ProductLogic.isFullAccessProduct(this.product)
            },

            stepperIndex: {
                get() {
                    return this.step
                },
                set(step) {
                    this.$emit('update:step', step)
                }
            },

            currentPlan: {
                get() {
                    return this.value
                },
                set(plans) {
                    this.$emit('input', plans)
                }
            },
            courseModulesSelectedValue: {
                get() {
                    return this.courseModulesSelected;
                },
                set(value) {
                    this.$emit('update:courseModulesSelected', value)
                }
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },

            currentPlanFormComponent() {
                return this.$refs.currentPlanForm
            },
        },

        watch: {
            'currentPlan': {
                handler() {
                    this.syncPlan()
                },
                deep: true
            },
        },

        methods: {
            syncPlan() {
                if (this.currentPlan.courseModules && this.currentPlan.courseModules.data) {
                    this.courseModulesSelectedValue = _.map(
                        this.currentPlan.courseModules.data,
                        (courseModule) => (courseModule.id)
                    )

                    this.courseModulesSelected.forEach(courseModule => {
                        this.rowActive[courseModule.id] = !!_.find(this.courseModulesSelectedValue, (courseModulesSelectedId) => {
                            return courseModule.id === courseModulesSelectedId;
                        });
                    });
                }
            },

            submit() {
                const plan = Object.assign({}, this.currentPlan)

                delete plan.id;
                delete plan.products;

                let body = {
                    ...plan,
                    product_id: this.product.id,
                    course_modules: this.courseModulesSelectedValue
                }

                if (this.currentPlan.selectedAssociatedPlans) {
                    body['associatedPlans'] = this.currentPlan.selectedAssociatedPlans;
                }

                this.$emit('submit', body);
            },

            selectCourseModule(courseModule) {
                const index = _.findIndex(this.courseModulesSelectedValue, selectedCourseModule => {
                    return selectedCourseModule === courseModule.id
                });

                if (index > -1) {
                    this.courseModulesSelectedValue.splice(index, 1);

                } else {
                    this.courseModulesSelectedValue.push(courseModule.id);
                }
            },


            resetValidation() {
                return this.currentPlanFormComponent.resetValidation();
            },

            addFormErrors(errors) {
                this.MgAlertComponent.displayAlert();
                Helpers.addFormErrors(this.$validator, errors);
            },
        }
    }
</script>
