<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>

        <v-stepper v-model="stepperIndex" vertical class="transparent">
            <v-stepper-step :complete="stepperIndex > 1" :step="1" editable>
                Vente à la carte
            </v-stepper-step>

            <v-stepper-content step="1">
                <sku-form
                        dark
                        ref="currentSkuForm"
                        submitBtn="Continuer"
                        submitBtnColor="secondary lighten-1"
                        @submit="getNextStepOrSubmit"
                        @associtedSkusSelected="setAssocitedSkusSelected()"
                        :showVip="!!product.vipProduct"
                        :product.sync="product"
                        v-model="currentSku">
                </sku-form>
            </v-stepper-content>

            <v-stepper-step :complete="stepperIndex > 2" :step="2" v-if="!isFullAccessProduct">
                Modules
            </v-stepper-step>

            <v-stepper-content step="2">
                <v-list two-line subheader v-for="selectedCourse in selectedCourses" :key="selectedCourse.id">
                    <v-subheader>{{selectedCourse.title}}</v-subheader>

                    <template v-if="selectedCourse.courseModules">
                        <v-list-item v-for="courseModule in selectedCourse.courseModules.data" :key="courseModule.id"
                                     class="clickable">
                            <v-list-item-action>
                                <v-checkbox v-model="courseModulesSelectedValue"
                                            color="secondary"
                                            @change="$emit('change', $event)"
                                            :ref="courseModule.id"
                                            :value="courseModule.id"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content @click="selectCourseModule(courseModule)">
                                <v-list-item-title>{{courseModule.title}}</v-list-item-title>

                                <v-list-item-subtitle>
                                    <template v-if="courseModule.lessons && courseModule.lessons.data">
                                        {{courseModule.lessons.data.length}} leçon<span
                                            v-if="courseModule.lessons.data.length > 1">s</span>
                                    </template>
                                    <template v-else>
                                        0 leçon
                                    </template>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                </v-list>

                <div class="text-center">
                    <v-btn color="secondary lighten-1"
                           :loading="loading"
                           @click="submit">Enregistrer
                    </v-btn>
                </div>
            </v-stepper-content>
        </v-stepper>
    </v-card>
</template>

<script>
    import _ from 'lodash'
    import Helpers from "mg-js-core/lib/services/Helpers";
    import ProductLogic from "mg-js-core/lib/logics/ProductLogic";

    export default {
        name: 'sku-create-stepper',

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            dark: Boolean,
            showToolbar: String,
            toolbarTitle: String,
            dialog: Boolean,
            isDialog: Boolean,

            value: {
                type: Object,
                required: true,
            },
            product: {
                type: Object,
                required: true,
            },
            loading: Boolean,
            step: {},
            selectedCourses: Array,

            showSelectCourses: Boolean,
            availableProducts: Array,

            courseModulesSelected: Array,
        },

        data: () => ({
            rowActive: {},
            // selectedCourses: []
        }),

        mounted() {
            this.syncSku()
        },

        computed: {
            stepperIndex: {
                get() {
                    return this.step
                },
                set(step) {
                    this.$emit('update:step', step)
                }
            },

            currentSku: {
                get() {
                    return this.value
                },
                set(skus) {
                    this.$emit('input', skus)
                }
            },
            courseModulesSelectedValue: {
                get() {
                    return this.courseModulesSelected;
                },
                set(value) {
                    this.$emit('update:courseModulesSelected', value)
                }
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },

            currentSkuFormComponent() {
                return this.$refs.currentSkuForm
            },

            isFullAccessProduct() {
                return ProductLogic.isFullAccessProduct(this.product)
            },

        },

        watch: {
            'currentSku': {
                handler() {
                    this.syncSku()
                },
                deep: true
            },
        },

        methods: {
            syncSku() {
                if (this.currentSku.courseModules && this.currentSku.courseModules.data) {
                    this.courseModulesSelectedValue = _.map(
                        this.currentSku.courseModules.data,
                        (courseModule) => (courseModule.id)
                    )

                    this.courseModulesSelected.forEach(courseModule => {
                        this.rowActive[courseModule.id] = !!_.find(this.courseModulesSelectedValue, (courseModulesSelectedId) => {
                            return courseModule.id === courseModulesSelectedId;
                        });
                    });
                }
            },

            getNextStepOrSubmit() {
                if(!this.isFullAccessProduct){
                    this.stepperIndex = 2
                } else {
                    this.submit()
                }
            },

            submit() {
                const sku = Object.assign({}, this.currentSku)

                delete sku.id;
                delete sku.products;

                let body = {
                    ...this.currentSku,
                    inventory_type: 'infinite',
                    product_id: this.product.id,
                }

                if (!this.isFullAccessProduct) {
                    body.course_modules = this.courseModulesSelectedValue;
                }

                if (this.currentSku.selectedAssociatedSkus) {
                    body['associatedSkus'] = this.currentSku.selectedAssociatedSkus;
                }

                this.$emit('submit', body)
            },

            selectCourseModule(courseModule) {
                const index = _.findIndex(this.courseModulesSelectedValue, selectedCourseModule => {
                    return selectedCourseModule === courseModule.id
                });

                if (index > -1) {
                    this.courseModulesSelectedValue.splice(index, 1);

                } else {
                    this.courseModulesSelectedValue.push(courseModule.id);
                }
            },


            resetValidation() {
                return this.currentSkuFormComponent.resetValidation();
            },

            addFormErrors(errors) {
                this.MgAlertComponent.displayAlert();
                Helpers.addFormErrors(this.$validator, errors);
            },
        }
    }
</script>
