<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>
        <v-card-text>

            <v-container grid-list-md class="pa-0 container--fluid">
                <v-row>

                    <v-col cols="12" v-if="showProducts">
                        <v-autocomplete
                                :disabled="disabled || !!plan.id"
                                :items="availableProducts"
                                v-model="plan.product_id"
                                item-text="title"
                                item-value="id"
                                :search-input="search"
                                @update:search-input="$emit('update:search', $event)"
                                :error-messages="errors.collect('product_id')"
                                data-vv-name="product_id"
                                v-validate="'required'"
                                label="Produit *"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" v-if="showCourses">
                        <v-autocomplete
                                :disabled="disabled || !!plan.id"
                                :items="availableCourses"
                                :value="selectedCourses"
                                @input="$emit('update:selectedCourses', $event)"
                                item-text="title"
                                item-value="id"
                                returnObject
                                multiple
                                :search-input="searchCourse"
                                @update:search-input="$emit('update:searchCourse', $event)"
                                :error-messages="errors.collect('course_id')"
                                data-vv-name="course_id"
                                v-validate="'required'"
                                label="Cours *"
                        >
                            <template v-slot:selection="data">
                                <v-chip class="caption" :disabled="disabled || !!plan.id">
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                                v-validate="'required'"
                                v-model="plan.title"
                                ref="titleInput"
                                :error-messages="errors.collect('title')"
                                label="Nom *"
                                name="title"
                                data-vv-title="title"
                                required
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                                v-model="plan.description"
                                ref="descriptionInput"
                                :error-messages="errors.collect('description')"
                                label="Description"
                                name="description"
                                data-vv-title="description"
                                required
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4" v-if="canEditStripeId">
                        <v-checkbox
                            v-validate
                            v-model="plan.import_from_stripe"
                            :error-messages="errors.collect('import_from_stripe')"
                            label="Import depuis stripe"
                            data-vv-name="import_from_stripe"
                            required
                            :true-value="true"
                            :false-value="false"
                            @change="fields.import_from_stripe.dirty = true"
                            color="secondary"
                            :disabled="!canEditStripeId"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="12" :sm="canEditStripeId ? 8 : 12">
                        <v-text-field
                            :disabled="canEditStripeId === false || plan.import_from_stripe === false"
                            v-model="plan.stripe_id"
                            label="Stripe Id"
                            v-validate
                            data-vv-name="stripe_id"
                            :error-messages="errors.collect('stripe_id')"
                        >
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="showPrice">
                        <mg-currency-field
                                v-validate="'required|decimal:2|min_value:1'"
                                :disabled="disabled || !!plan.id"
                                v-model="plan.price"
                                ref="priceInput"
                                label="Prix *"
                                required
                                :currency="plan.currency"
                        ></mg-currency-field>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="showCurrency">
                        <v-autocomplete
                                :items="currencies"
                                :disabled="disabled || !!plan.id"
                                v-model="plan.currency"
                                :error-messages="errors.collect('currency')"
                                data-vv-name="currency"
                                v-validate="'required'"
                                label="Devise *"
                        ></v-autocomplete>
                    </v-col>

                    <!--<v-flex xs12 pa-O v-if="showCurrency || showPrice">-->
                    <!--<v-layout row>-->

                    <!--</v-layout>-->
                    <!---->
                    <!--</v-flex>-->


                    <v-col class="pa-O" cols="12" v-if="showPlanInterval">
                        <v-select
                                :items="planIntervals"
                                :disabled="disabled || !!plan.id"
                                :filter="customFilterPlanInterval"
                                v-model="planInterval"
                                @change="setIntervalCount"
                                :error-messages="errors.collect('interval')"
                                data-vv-name="interval"
                                v-validate="'required'"
                                label="Intervalle de facturation *"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" v-if="showVip">
                        <v-row align="center">

                            <v-checkbox
                                    class="shrink"
                                    v-validate
                                    v-model="plan.vip_allowed"
                                    :error-messages="errors.collect('vip_allowed')"
                                    label="Option VIP"
                                    data-vv-name="vip_allowed"
                                    required
                                    :true-value="true"
                                    :false-value="false"
                                    @change="fields.vip_allowed.dirty = true"
                                    color="secondary"
                            ></v-checkbox>

                            <mg-currency-field
                                    v-if="plan.vip_allowed"
                                    @input="fields.vip_allowed.dirty = true"
                                    class="ml-5"
                                    v-validate="'required|decimal:2|min_value:0'"
                                    v-model="plan.vip_price"
                                    ref="priceVipInput"
                                    label="Prix option VIP *"
                                    name="vip_price"
                                    required
                                    :currency="plan.currency"
                            ></mg-currency-field>
                        </v-row>
                    </v-col>


                    <v-col cols="12">
                        <v-row align="center">
                            <v-checkbox
                                    v-validate
                                    v-model="plan.enabled"
                                    :error-messages="errors.collect('enabled')"
                                    label="Activé"
                                    data-vv-name="enabled"
                                    :true-value="true"
                                    :false-value="false"
                                    @change="fields.enabled.dirty = true"
                                    color="secondary"
                            ></v-checkbox>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-row align="center">
                            <v-text-field
                                v-model="plan.promo_text"
                                ref="promoTextInput"
                                :error-messages="errors.collect('promo_text')"
                                label="Texte promo"
                                name="promo_text"
                                data-vv-title="promo_text"
                            ></v-text-field>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <div style="font-size: 20px; font-weight: bold;">Bump & Crossell</div>
                        <p>Ajouter un ou plusieurs Plan associés</p>

                            <ul v-if="associatedPlans.length > 0">
                                <li v-for="associatedPlan in associatedPlans" :key="associatedPlan.id">
                                    <div class="d-flex justify-space-betwee align-baseline mt-2">
                                        <div class="mr-10">
                                            Plan : {{ associatedPlan.product_title }} - <strong>{{ associatedPlan.title }}</strong>
                                        </div>
                                        <div class="mr-10">
                                            <v-btn @click="removeAssociatedPlan(associatedPlan)" style="background: red; color: white;">
                                                Supprimer
                                            </v-btn>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        <v-btn color="primary" @click="dialogBumpPlan = true">
                            Ajouter un Plan associé
                        </v-btn>
                    </v-col>

                    <v-col class="pa-O" cols="12" v-if="showCourseModules">
                        <div class="caption"
                             style="color: rgba(0,0,0,0.54)">
                            Modules
                        </div>

                        <v-list two-line subheader v-for="selectedCourse in selectedCourses" :key="selectedCourse.id">
                            <v-subheader>{{selectedCourse.title}}</v-subheader>

                            <v-list-item v-for="courseModule in selectedCourse.courseModules.data"
                                         v-show="selectedCourse.courseModules"
                                         :key="courseModule.id"
                                         class="clickable">
                                <v-list-item-action>
                                    <v-checkbox v-model="courseModulesSelectedValue"
                                                color="secondary"
                                                @change="$emit('change', $event)"
                                                :ref="courseModule.id"
                                                :value="courseModule.id"></v-checkbox>
                                </v-list-item-action>

                                <v-list-item-content @click="selectCourseModule(courseModule)">
                                    <v-list-item-title>{{courseModule.title}}</v-list-item-title>

                                    <v-list-item-subtitle>
                                        <template v-if="courseModule.lessons && courseModule.lessons.data">
                                            {{courseModule.lessons.data.length}} leçon<span
                                                v-if="courseModule.lessons.data.length > 1">s</span>
                                        </template>
                                        <template v-else>
                                            0 leçon
                                        </template>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-text class="pa-1" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="isLoading"
                   @click="submit">
                {{submitBtn}}
            </v-btn>
        </v-card-text>

        <v-dialog v-model="dialogBumpPlan" max-width="900px">
            <v-card flat tile color="creamy">
                <v-app-bar tile flat dense dark color="secondary">
                    <v-btn icon small fab dark @click="dialogBumpPlan = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div class="flex-grow-1">Ajouter un Plan associé</div>
                </v-app-bar>
                <v-stepper v-model="stepperIndex" vertical class="transparent pb-2">
                    <v-stepper-step :complete="stepperIndex > 1" :step="1" editable>
                        Produit par abonnement
                    </v-stepper-step>

                    <v-stepper-content :step="1">
                        <v-col cols="12">
                            <v-autocomplete
                                    :items="availableSubscriptionProducts"
                                    v-model="selectedSubscriptionProduct"
                                    item-text="title"
                                    item-value="id"
                                    returnObject
                                    cache-items
                                    :search-input.sync="searchSubscriptionProduct"
                                    :error-messages="errors.collect('subscription-product')"
                                    data-vv-name="subscription-product"
                                    label="Produit par abonnement *"
                            >
                                <template v-slot:selection="data">
                                    <v-chip class="caption">
                                        {{ data.item.title }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-btn color="secondary" @click="stepperIndex++" :disabled="!selectedSubscriptionProduct?.id">Continuer</v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="stepperIndex > 2" :step="2" :editable="(selectedSubscriptionProduct?.id ? true : false)">
                        Plan
                    </v-stepper-step>

                    <v-stepper-content :step="2">
                        <v-col cols="12">
                            <v-autocomplete
                                    :items="availablePlans"
                                    v-model="selectedPlan"
                                    item-text="title"
                                    item-value="id"
                                    returnObject
                                    :search-input.sync="searchPlan"
                                    :error-messages="errors.collect('plans')"
                                    data-vv-name="plans"
                                    label="Plan*"
                            >
                                <template v-slot:selection="data">
                                    <v-chip class="caption">
                                        {{ data.item.title }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-btn color="secondary lighten-1 mt-6 mb-2"
                            @click="addAssociatedPlan">Valider
                        </v-btn>
                    </v-stepper-content>
                </v-stepper>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import _ from 'lodash'
    import PlanLogic from 'mg-js-core/lib/logics/PlanLogic';
    import ProductLogic from 'mg-js-core/lib/logics/ProductLogic';

    export default {
        name: 'plan-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            products: Array,

            showProducts: Boolean,
            showVip: Boolean,
            availableProducts: Array,
            showCourses: Boolean,
            availableCourses: Array,
            selectedCourses: Array,

            canEditStripeId: {
                type: Boolean,
                default: false
            },

            showCurrency: {
                type: Boolean,
                default: true,
            },
            showPrice: {
                type: Boolean,
                default: true,
            },
            showPlanInterval: {
                type: Boolean,
                default: true,
            },

            showCourseModules: Boolean,
            courseModules: Array,
            courseModulesSelected: Array,

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,

            dialog: Boolean,
            isDialog: Boolean,
            isLoading: Boolean,
            disabled: Boolean,

            value: Object,
            search: String,
            searchCourse: String,

            showToolbar: {
                type: String,
                default: null
            },
            dark: Boolean,
        },

        data: () => ({
            planInterval: null,
            currencies: [
                {
                    text: 'Euro (€)', value: 'EUR'
                },
                {
                    text: 'Dollar ($)', value: 'USD'
                },
            ],
            planIntervals: [
                {
                    text: 'Mensuel', value: 'month'
                },
                {
                    text: 'Trimestriel', value: 'quarterly'
                },
                {
                    text: 'Annuel', value: 'year'
                },
            ],

            dialogBumpPlan: false,
            associatedPlans: [],
            stepperIndex: 1,

            /* plans */
            plansPagination: Helpers.initPagination(1000, 1, "title", true),
            availablePlans: [],
            selectedPlan: null,
            searchPlan: '',
            selectPlan: null,

            /* subscription products */
            availableSubscriptionProductsPagination: Helpers.initPagination(1000, 1, "title", true),
            availableSubscriptionProducts: [],
            selectedSubscriptionProduct: null,
            searchSubscriptionProduct: '',
            selectSubscriptionProduct: null,
        }),


        mounted() {
            this.syncValue(this.value)
            this.getAllSubscriptionProductsAvailable();
        },

        watch: {
            plan(newValue) {
                if (newValue) {
                    this.syncValue(newValue)
                }
            },
            'searchPlan': {
                handler() {
                    this.getAllPlansAvailable();
                },
                deep: true
            },
            'searchSubscriptionProduct' : {
                handler() {
                    this.getAllSubscriptionProductsAvailable();
                },
                deep: true 
            },
            'selectedSubscriptionProduct': {
                handler() {
                    this.getAllPlansAvailable();
                },
                deep: true 
            }
        },

        computed: {
            plan: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            courseModulesSelectedValue: {
                get() {
                    return this.courseModulesSelected;
                },
                set(value) {
                    this.$emit('update:courseModulesSelected', value)
                }
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },

            buttonStyle() {
                if (!this.isDirty) {
                    return {visibility: 'hidden'}
                } else {
                    return {}
                }
            },

            isDirty() {
                return Helpers.isFormDirty(this)
            },
        },

        methods: {
            syncValue(plan) {
                this.dialogBumpPlan = false;
                this.associatedPlans = [];
                this.stepperIndex =  1;

                /* plans */
                this.plansPagination = Helpers.initPagination(1000, 1, "title", true);
                this.availablePlans = [];
                this.selectedPlan = null;
                this.searchPlan = '';
                this.selectPlan = null;

                /* subscription products */
                this.availableSubscriptionProductsPagination = Helpers.initPagination(1000, 1, "title", true);
                this.selectedSubscriptionProduct = null;
                this.searchSubscriptionProduct = '';
                this.selectSubscriptionProduct = null;


                this.planInterval = this.getPlanInterval(plan)

                if (plan.product) {
                    this.plan.product_id = plan.product.data.id
                }

                if (plan.course) {
                    this.plan.course_id = plan.course.data.id
                }

                if (plan.courseModules && plan.courseModules.data) {
                    this.courseModulesSelectedValue = _.map(
                        plan.courseModules.data,
                        courseModule => courseModule.id
                    )
                }

                if (plan.associatedPlans.data.length > 0) {
                    plan.associatedPlans.data.forEach(associatedPlan => {
                        this.associatedPlans.push({
                            'id': associatedPlan.associated_plan.uuid,
                            'title': associatedPlan.associated_plan.title,
                            'product_id': associatedPlan.associated_plan_product.uuid,
                            'product_title': associatedPlan.associated_plan_product.title,
                        })
                    });
                }

            },
            getPlanInterval(plan) {
                let interval = '';

                if (plan.interval === 'year') {
                    interval = 'year'
                } else if (plan.interval === 'month') {
                    if (plan.interval_count === 1) {
                        interval = 'month'
                    } else if (plan.interval_count === 3) {
                        interval = 'quarterly'
                    }
                }

                return interval;
            },

            setIntervalCount(interval) {
                if (interval === 'quarterly') {
                    this.$set(this.plan, 'interval', 'month')
                    this.$set(this.plan, 'interval_count', 3)

                } else if (interval === 'semester') {
                    this.$set(this.plan, 'interval', 'month')
                    this.$set(this.plan, 'interval_count', 6)
                } else {
                    this.$set(this.plan, 'interval', interval)
                    this.$set(this.plan, 'interval_count', 1)
                }
            },
            customFilter(item, queryText) {
                const name = item.name.toLowerCase()
                const searchText = queryText.toLowerCase()

                return name.indexOf(searchText) > -1
            },

            customFilterPlanInterval(item, queryText) {
                const name = item.text.toLowerCase()
                const searchText = queryText.toLowerCase()

                return name.indexOf(searchText) > -1
            },

            validatePlanForm() {
                return this.$validator.validateAll();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.MgAlertComponent.displayAlert();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            submit() {
                this.MgAlertComponent.closeAlert();

                this.validatePlanForm().then(isValid => {
                    if (isValid) {
                        let body = {
                            promo_text: this.plan.promo_text,
                        };

                        _.forIn(this.fields, (value, key) => {
                            if (value.dirty) {
                                body[key] = this.plan[key]
                            }
                        });

                        if (this.plan.description) {
                            body.description = this.plan.description;
                        }

                        body['course_modules'] = this.courseModulesSelectedValue

                        if (this.associatedPlans.length > 0) {
                            body['associatedPlans'] = this.associatedPlans;
                            this.plan.selectedAssociatedPlans = this.associatedPlans;
                        } else {
                            body['associatedPlans'] = [];
                        }

                        this.$emit('submit', this.plan.id, body);
                    }
                })
            },

            selectCourseModule(courseModule) {
                const index = _.findIndex(this.courseModulesSelectedValue, selectedCourseModule => {
                    return selectedCourseModule === courseModule.id
                });

                if (index > -1) {
                    this.courseModulesSelectedValue.splice(index, 1);

                } else {
                    this.courseModulesSelectedValue.push(courseModule.id);
                }
            },

            getAllSubscriptionProductsAvailable: _.debounce(function() {
                ProductLogic
                    .getAll(this.availableSubscriptionProductsPagination, {
                        search : this.searchSubscriptionProduct,
                        subscriptionOnly: true,
                    })
                    .then(({data}) => {
                        this.availableSubscriptionProducts = data;
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }, 300),

            getAllPlansAvailable: _.debounce(function() {
                PlanLogic
                    .getAll(this.plansPagination, {
                        search : this.searchPlan,
                        enabled: 1,
                        product_id: this.selectedSubscriptionProduct.id,
                        interval: this.planInterval,
                    })
                    .then(({data}) => {
                        this.availablePlans = data;
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }, 300),

            addAssociatedPlan() {
                const existingPlan = this.associatedPlans.find(planItem => planItem.id === this.selectedPlan.id);

                if (!existingPlan) {
                    this.associatedPlans.push({
                        'id': this.selectedPlan.id,
                        'product_id': this.selectedSubscriptionProduct.id,
                        'product_title': this.selectedSubscriptionProduct.title,
                        'title': this.selectedPlan.title,
                    });
                }

                this.selectedPlan = null;
                this.selectedSubscriptionProduct = null;
                this.dialogBumpPlan = false;
                this.stepperIndex = 1;
            },

            removeAssociatedPlan(plan) {
                this.associatedPlans = this.associatedPlans.filter(el => el.id !== plan.id);
            }
        }
    }
</script>
