<template>
    <v-card flat>
        <template v-if="showToolbar">
            <v-app-bar flat dense :dark="dark" :color="showToolbar">
                <v-toolbar-title class="font-weight-light">{{toolbarTitle}}</v-toolbar-title>

                <template v-if="isDialog">
                    <v-spacer></v-spacer>

                    <v-btn icon dark
                           @click="$emit('update:dialog', false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-app-bar>

            <v-divider/>

        </template>

        <mg-alert ref="mgAlert"></mg-alert>
        <v-card-text>

            <v-container grid-list-md class="pa-0 container--fluid">
                <v-row>

                    <v-col cols="12" v-if="showProducts">
                        <v-autocomplete
                                :disabled="disabled"
                                :items="availableProducts"
                                v-model="sku.product_id"
                                item-text="title"
                                item-value="id"
                                :search-input="search"
                                @update:search-input="$emit('update:search', $event)"
                                :error-messages="errors.collect('product_id')"
                                data-vv-name="product_id"
                                v-validate="'required'"
                                label="Produit *"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" v-if="showCourses">
                        <v-autocomplete
                                :disabled="disabled || !!sku.id"
                                :items="availableCourses"
                                :value="selectedCourses"
                                @input="$emit('update:selectedCourses', $event)"
                                item-text="title"
                                item-value="id"
                                returnObject
                                multiple
                                :search-input="searchCourse"
                                @update:search-input="$emit('update:searchCourse', $event)"
                                :error-messages="errors.collect('course_id')"
                                data-vv-name="course_id"
                                v-validate="'required'"
                                label="Cours *"
                        >
                            <template v-slot:selection="data">
                                <v-chip class="caption" :disabled="disabled || !!sku.id">
                                    {{ data.item.title }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                                v-validate="'required'"
                                v-model="sku.title"
                                ref="titleInput"
                                :error-messages="errors.collect('title')"
                                label="Nom *"
                                name="title"
                                data-vv-title="title"
                                required
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="showPrice">
                        <mg-currency-field
                                v-validate="'required|decimal:2|min_value:0'"
                                :disabled="disabled"
                                v-model="sku.price"
                                ref="priceInput"
                                label="Prix *"
                                required
                                :min="0"
                                :currency="sku.currency"
                        ></mg-currency-field>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="showCurrency">
                        <v-autocomplete
                                :items="currencies"
                                :disabled="disabled"
                                v-model="sku.currency"
                                :error-messages="errors.collect('currency')"
                                data-vv-name="currency"
                                v-validate="'required'"
                                label="Devise *"
                        ></v-autocomplete>
                    </v-col>

                    <!--<v-flex xs12 pa-O v-if="showCurrency || showPrice">-->
                    <!--<v-layout row>-->

                    <!--</v-layout>-->
                    <!---->
                    <!--</v-flex>-->

                    <v-col cols="12" v-if="showVip">
                        <v-row align="center">

                            <v-checkbox
                                    class="shrink"
                                    v-validate
                                    v-model="sku.vip_allowed"
                                    :disabled="disabled"
                                    :error-messages="errors.collect('vip_allowed')"
                                    label="Option VIP"
                                    data-vv-name="vip_allowed"
                                    required
                                    :true-value="true"
                                    :false-value="false"
                                    @change="fields.vip_allowed.dirty = true"
                                    color="secondary"
                            ></v-checkbox>

                            <mg-currency-field
                                    v-if="sku.vip_allowed"
                                    class="ml-5"
                                    v-validate="'required|decimal:2|min_value:0'"
                                    :disabled="disabled"
                                    v-model="sku.vip_price"
                                    ref="priceVipInput"
                                    label="Prix option VIP *"
                                    name="vip_price"
                                    required
                                    :currency="sku.currency"
                            ></mg-currency-field>
                        </v-row>
                    </v-col>


                    <v-col cols="12">
                        <v-row align="center">
                            <v-checkbox
                                    v-validate
                                    v-model="sku.enabled"
                                    :error-messages="errors.collect('enabled')"
                                    label="Activé"
                                    data-vv-name="enabled"
                                    :true-value="true"
                                    :false-value="false"
                                    @change="fields.enabled.dirty = true"
                                    color="secondary"
                            ></v-checkbox>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-row align="center">
                            <v-text-field
                                v-model="sku.promo_text"
                                ref="promoTextInput"
                                :error-messages="errors.collect('promo_text')"
                                label="Texte promo"
                                name="promo_text"
                                data-vv-title="promo_text"
                            ></v-text-field>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <div style="font-size: 20px; font-weight: bold;">Bump & Crossell</div>
                        <p>Ajouter un ou plusieurs Sku associés avec une réduction unique (ou pas).</p>

                            <ul v-if="associatedSkus.length > 0">
                                <li v-for="associatedSku in associatedSkus" :key="associatedSku.id">
                                    <div class="d-flex justify-space-betwee align-baseline mt-2">
                                        <div class="mr-10">
                                            Sku : <strong>{{ associatedSku.title }}</strong>
                                        </div>
                                        <div class="mr-10">
                                            Réduction : <strong>{{ associatedSku.discount_in_percent }}%</strong>
                                        </div>
                                        <div class="mr-10">
                                            <v-btn @click="removeAssociatedSku(associatedSku)" style="background: red; color: white;">
                                                Supprimer
                                            </v-btn>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        <v-btn color="primary" @click="dialogBumpSku = true">
                            Ajouter un Sku associé
                        </v-btn>
                    </v-col>

                    <v-col class="pa-O" cols="12" v-if="showCourseModules && !isFullAccessProduct">
                        <div class="caption"
                             style="color: rgba(0,0,0,0.54)">
                            Modules
                        </div>

                        <v-list two-line subheader v-for="selectedCourse in selectedCourses" :key="selectedCourse.id">
                            <v-subheader>{{selectedCourse.title}}</v-subheader>

                            <v-list-item v-for="courseModule in selectedCourse.courseModules.data"
                                         v-show="selectedCourse.courseModules"
                                         :key="courseModule.id"
                                         class="clickable">
                                <v-list-item-action>
                                    <v-checkbox v-model="courseModulesSelectedValue"
                                                color="secondary"
                                                @change="$emit('change', $event)"
                                                :ref="courseModule.id"
                                                :value="courseModule.id"></v-checkbox>
                                </v-list-item-action>

                                <v-list-item-content @click="selectCourseModule(courseModule)">
                                    <v-list-item-title>{{courseModule.title}}</v-list-item-title>

                                    <v-list-item-subtitle>
                                        <template v-if="courseModule.lessons && courseModule.lessons.data">
                                            {{courseModule.lessons.data.length}} leçon<span
                                                v-if="courseModule.lessons.data.length > 1">s</span>
                                        </template>
                                        <template v-else>
                                            0 leçon
                                        </template>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-text class="pa-1" :class="submitBtnClass" v-if="submitBtn">
            <v-btn :color="submitBtnColor"
                   :loading="isLoading"
                   @click="submit">
                {{submitBtn}}
            </v-btn>
        </v-card-text>

        <v-dialog v-model="dialogBumpSku" max-width="900px">
            <v-card flat tile color="creamy">
                <v-app-bar tile flat dense dark color="secondary">
                    <v-btn icon small fab dark @click="dialogBumpSku = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div class="flex-grow-1">Ajouter un Sku associé</div>
                </v-app-bar>

                <v-row
                    class="pt-3"
                    justify="center">
                    <v-col cols="12" md="11" xl="9">
                        <v-autocomplete
                            :items="skus"
                            v-model="selectedSku"
                            item-text="title"
                            item-value="id"
                            returnObject
                            cache-items
                            :search-input.sync="searchSku"
                            data-vv-name="skus"
                            label="Sku associé"
                            >
                                <template v-slot:selection="data">
                                    <v-chip class="caption">
                                        {{ data.item.title }}
                                    </v-chip>
                                </template>
                        </v-autocomplete>
                        <v-text-field
                            v-model="discount_in_percent"
                            ref="discount_in_percent"
                            :error-messages="errors.collect('discount_in_percent')"
                            label="Réduction BUMP sur ce Sku (%)"
                            name="discount_in_percent"
                            suffix="%"
                            type="number"
                            :rules="[v => (v >= 0 && v <= 100) || 'La valeur doit être comprise entre 0 et 100']"
                            data-vv-title="discount_in_percent"
                        ></v-text-field>

                        <v-card-text class="mb-2">
                            <v-btn @click="addAssociatedSku" color="primary">
                                Ajouter
                            </v-btn>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers';
    import MgAlert from "mg-js-core/src/components/communs/MgAlert";
    import _ from 'lodash'
    import ProductLogic from "mg-js-core/lib/logics/ProductLogic";
    import SkuLogic from "mg-js-core/lib/logics/SkuLogic";

    export default {
        name: 'sku-form',

        components: {MgAlert},

        $_veeValidate: {
            validator: 'new',
        },

        props: {
            toolbarTitle: String,

            product: Object,

            showProducts: Boolean,
            showVip: Boolean,
            availableProducts: Array,
            showCourses: Boolean,
            availableCourses: Array,
            selectedCourses: Array,

            showCurrency: {
                type: Boolean,
                default: true,
            },
            showPrice: {
                type: Boolean,
                default: true,
            },

            showCourseModules: Boolean,
            courseModules: Array,
            courseModulesSelected: Array,

            submitBtn: String,
            submitBtnColor: String,
            submitBtnClass: String,

            dialog: Boolean,
            isDialog: Boolean,
            isLoading: Boolean,
            disabled: Boolean,

            value: Object,
            search: String,
            searchCourse: String,

            showToolbar: {
                type: String,
                default: null
            },
            dark: Boolean,
        },

        data: () => ({
            currencies: [
                {
                    text: 'Euro (€)', value: 'EUR'
                },
                {
                    text: 'Dollar ($)', value: 'USD'
                },
            ],

            skus: [],
            searchSku: "",
            selectedSku: null,
            discount_in_percent: 0,
            skusPagination: Helpers.initPagination(1000, 1, "title", true),
            dialogBumpSku: false,
            associatedSkus: [],
        }),


        mounted() {
            this.syncValue(this.value);
            this.fetchSkus();
        },

        watch: {
            sku(newValue) {
                if (newValue) {
                    this.syncValue(newValue)
                }
            },
            'searchSku': {
                handler() {
                    this.fetchSkus();
                },
                deep: true
            },
        },

        computed: {
            sku: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            courseModulesSelectedValue: {
                get() {
                    return this.courseModulesSelected;
                },
                set(value) {
                    this.$emit('update:courseModulesSelected', value)
                }
            },

            MgAlertComponent() {
                return this.$refs.mgAlert
            },

            buttonStyle() {
                if (!this.isDirty) {
                    return {visibility: 'hidden'}
                } else {
                    return {}
                }
            },

            isDirty() {
                return Helpers.isFormDirty(this)
            },

            isFullAccessProduct() {
                return ProductLogic.isFullAccessProduct(this.product)
            },
        },

        methods: {
            syncValue(sku) {
                this.selectedSku = null;
                this.discount_in_percent = 0;
                this.skusPagination = Helpers.initPagination(1000, 1, "title", true);
                this.dialogBumpSku = false;
                this.associatedSkus = [];

                if (sku.product) {
                    this.sku.product_id = sku.product.data.id
                }

                if (sku.course) {
                    this.sku.course_id = sku.course.data.id
                }

                if (sku.courseModules && sku.courseModules.data) {
                    this.courseModulesSelectedValue = _.map(
                        sku.courseModules.data,
                        courseModule => courseModule.id
                    )
                }

                if (sku.associatedSkus.data.length > 0) {
                    sku.associatedSkus.data.forEach(associatedSku => {
                        this.associatedSkus.push({
                            'id': associatedSku.associated_sku.uuid,
                            'title': associatedSku.associated_sku.title,
                            'discount_in_percent': associatedSku.discount_in_percent,
                        })
                    });
                }

            },
            customFilter(item, queryText) {
                const name = item.name.toLowerCase()
                const searchText = queryText.toLowerCase()

                return name.indexOf(searchText) > -1
            },

            validateSkuForm() {
                return this.$validator.validateAll();
            },

            addFormErrors(errors) {
                Helpers.addFormErrors(this.$validator, errors);
                this.MgAlertComponent.displayAlert();
            },

            resetValidation() {
                return this.$validator.reset();
            },

            submit() {
                this.MgAlertComponent.closeAlert();

                this.validateSkuForm().then(isValid => {
                    if (isValid) {
                        let body = {
                            inventory_type: 'infinite',
                            promo_text: this.sku.promo_text,
                        };

                        _.forIn(this.fields, (value, key) => {
                            body[key] = this.sku[key]
                        });

                        if (!this.isFullAccessProduct) {
                            body['course_modules'] = this.courseModulesSelectedValue                        // }
                        }

                        if (this.associatedSkus.length > 0) {
                            body['associatedSkus'] = this.associatedSkus;
                            this.sku.selectedAssociatedSkus = this.associatedSkus;
                        } else {
                            body['associatedSkus'] = [];
                        }

                        this.$emit('submit', this.sku.id, body);
                    }
                })
            },

            selectCourseModule(courseModule) {
                const index = _.findIndex(this.courseModulesSelectedValue, selectedCourseModule => {
                    return selectedCourseModule === courseModule.id
                });

                if (index > -1) {
                    this.courseModulesSelectedValue.splice(index, 1);

                } else {
                    this.courseModulesSelectedValue.push(courseModule.id);
                }
            },

            fetchSkus: _.debounce(function () {
                SkuLogic
                    .getAll(this.skusPagination, {
                        search: this.searchSku,
                        enabled: 1,
                    })
                    .then(({data}) => {
                        this.skus = data;
                    })
                    .catch((err) => {
                        console.error(err)
                    })

            }, 300),

            addAssociatedSku() {
                const existingSku = this.associatedSkus.find(skuItem => skuItem.id === this.selectedSku.id);

                if (existingSku) {
                    existingSku.discount_in_percent = this.discount_in_percent;
                } else {
                    this.associatedSkus.push({
                        'id': this.selectedSku.id,
                        'title': this.selectedSku.title,
                        'discount_in_percent': this.discount_in_percent,
                    });
                }

                this.selectedSku = null;
                this.discount_in_percent = 0;
                this.dialogBumpSku = false;
            },

            removeAssociatedSku(sku) {
                this.associatedSkus = this.associatedSkus.filter(el => el.id !== sku.id);
            }

        }
    }
</script>
