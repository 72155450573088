<template>
    <v-row>
        <v-col>
            <v-card>
                <v-app-bar dense flat dark :color="toolbar">
                    <v-btn icon dark
                           v-if="isDialog"
                           @click="$emit('update:dialog', $event)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{toolbarTitle}} <span v-if="showDataCount">({{totalItems}})</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip bottom v-if="hasClickAdd">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on"
                                   @click="$emit('update:dialogAdd', true)"
                                   icon>
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Ajouter</span>
                    </v-tooltip>
                </v-app-bar>

                <v-row justify="center">
                    <v-col cols="12" sm="6" md="2" >
                        <v-text-field
                                v-model="filtersLocal.search"
                                ref="search"
                                prepend-icon="mdi-magnify"
                                @click:prepend="$refs.search.focus()"
                                clearable
                                label="Nom ou prénom ou email"
                        ></v-text-field>
                    </v-col>
                    <v-col class="align-self-center" cols="12" sm="4" md="2" v-if="filtersLocal.hasOwnProperty('isCustomer')">
                        <v-select
                            v-model="filtersLocal.isCustomer"
                            :items="selectFilterItems"
                            item-text="text"
                            item-value="value"
                            label="Est client"
                            hide-details
                            dense>
                        </v-select>
                    </v-col>
                    <v-col class="align-self-center" cols="12" sm="4" md="2" v-if="filtersLocal.hasOwnProperty('hasUnlimited')">
                        <v-select
                            v-model="filtersLocal.hasUnlimited"
                            :items="selectFilterItems"
                            item-text="text"
                            item-value="value"
                            label="Accès illimité"
                            hide-details
                            dense>
                        </v-select>
                    </v-col>
                    <v-col class="align-self-center" cols="12" sm="4" md="2" v-if="filtersLocal.hasOwnProperty('isActiveOnMG')">
                        <v-select
                            v-model="filtersLocal.isActiveOnMG"
                            :items="selectFilterItems"
                            item-text="text"
                            item-value="value"
                            label="Actif sur MG"
                            hide-details
                            dense>
                        </v-select>
                    </v-col>
                    <v-col class="align-self-center" cols="12" sm="4" md="2" v-if="filtersLocal.hasOwnProperty('isActiveOnMPP')">
                        <v-select
                            v-model="filtersLocal.isActiveOnMPP"
                            :items="selectFilterItems"
                            item-text="text"
                            item-value="value"
                            label="Actif sur MPP"
                            hide-details
                            dense>
                        </v-select>
                    </v-col>
                    <v-col class="align-self-center" cols="12" sm="4" md="2" v-if="filtersLocal.hasOwnProperty('fromResourceRegister')">
                        <v-select
                            v-model="filtersLocal.fromResourceRegister"
                            :items="selectFilterItems"
                            item-text="text"
                            item-value="value"
                            label="Proviens des liens ressources"
                            hide-details
                            dense>
                        </v-select>
                    </v-col>
                    <v-col class="align-self-center" cols="12" sm="4" md="2" v-if="filtersLocal.hasOwnProperty('clubTier')">
                        <v-select
                            v-model="filtersLocal.clubTier"
                            :items="clubTiersFilters"
                            item-text="text"
                            item-value="value"
                            label="Abonnés à une offre club"
                            hide-details
                            dense>
                        </v-select>
                    </v-col>
                    <v-col class="align-self-center" cols="12" sm="4" md="2" v-if="filtersLocal.hasOwnProperty('clubPlatform')">
                        <v-select
                            v-model="filtersLocal.clubPlatform"
                            :items="clubPlatformItems"
                            item-text="text"
                            item-value="value"
                            label="Plateforme de l'abonnement club"
                            hide-details
                            dense>
                        </v-select>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-data-table
                        :options="pagination"
                        @update:options="$emit('update:pagination', $event)"
                        :server-items-length="totalItems"
                        :footer-props="{
                            'items-per-page-options' : [20, 50, 100, 150]
                        }"
                        :headers="headers"
                        :loading="isLoading"
                        :items="users">
                    <template v-slot:item.username="{ item }">
                        <router-link class="default--text"
                                     :to="{name: userEditRouteName, params: {uuid: item.id}}">
                            {{ item.username }} <v-badge v-if="item.deleted_at" color="error" content="Compte Supprimé" inline ></v-badge>
                        </router-link>
                    </template>
                    <template v-slot:item.email="{ item }">
                        <router-link class="default--text"
                                     :to="{name: userEditRouteName, params: {uuid: item.id}}">
                            {{ item.email }}
                        </router-link>
                    </template>
                    <template v-slot:item.is_customer="{ item }">
                        <v-icon v-if="item.is_customer">mdi-check</v-icon>
                        <v-icon v-else>mdi-close</v-icon>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ Helpers.parseDate(item.created_at) }}
                    </template>
                    <template v-slot:item.is_active_on_mg="{ item }">
                        {{ (item.is_active_on_mg ? 'Oui' : 'Non') }}
                    </template>
                    <template v-slot:item.is_active_on_mpp="{ item }">
                        {{ (item.is_active_on_mpp ? 'Oui' : 'Non') }}
                    </template>
                    <template v-slot:item.from_resource_register="{ item }">
                        {{ (item.from_resource_register ? 'Oui' : 'Non') }}
                    </template>
                </v-data-table>
            </v-card>
        </v-col>

        <v-dialog :value="dialogAdd" @input="$emit('update:dialogAdd', $event)" max-width="900px">

            <user-profile-form
                    ref="userProfileFormAdd"
                    isDialog
                    dark
                    :dialog="dialogAdd"
                    @update:dialog="$emit('update:dialogAdd', $event)"
                    cardTitle="Ajouter un utilisateur"
                    toolbar

                    color="secondary"
                    :loading="addLoading"
                    submitBtn="Enregistrer"
                    submitBtnClass="text-center"
                    submitBtnColor="secondary lighten-1"
                    @submit="createUser"
                    :creation="true"
                    :createError="createError"

                    :address="newUserAddress"
                    @update:address="$emit('update:address', $event)"

                    :value="newUser"
                    @input="$emit('update:newUser', $event)">
            </user-profile-form>
        </v-dialog>
    </v-row>
</template>

<script>
    import Helpers from 'mg-js-core/lib/services/Helpers'
    import PageStore from 'mg-js-core/lib/stores/PageStore'
    import UserLogic from "mg-js-core/lib/logics/UserLogic";

    export default {
        name: 'user-table',

        props: {

            toolbar: String,
            dark: Boolean,
            isDialog: Boolean,
            isLoading: Boolean,
            dialog: Boolean,
            toolbarTitle: String,
            showSearchFilter: Boolean,

            value: {
                type: Array,
                required: true,
            },

            userEditRouteName: {
                type: String,
                default : 'admin-user-edit'
            },

            showDataCount: {
                type: Boolean,
                default: false
            },
            filters: {
                type: Object,
                required: true
            },
            pagination: {
                type: Object,
                required: true,
            },
            totalItems: Number,


            /* add */
            dialogAdd: Boolean,
            addLoading: Boolean,
            newUser: Object,
            newUserAddress: Object,
            createError: Boolean,
        },

        data: () => ({
            PageStore,
            Helpers,

            pageTitle: 'Gestion des utilisateurs',

            paginationOptions: {},
            headers: [
                {
                    text: 'Pseudo',
                    sortable: true,
                    value: 'username'
                },
                {
                    text: 'Email',
                    sortable: true,
                    value: 'email'
                },
                {
                    text: 'Client',
                    sortable: false,
                    value: 'is_customer'
                },
                {
                    text: 'Inscrit le',
                    sortable: true,
                    value: 'created_at',
                },
                {
                    text: 'Actif sur MG',
                    sortable: false,
                    value: 'is_active_on_mg',
                },
                {
                    text: 'Actif sur MPP',
                    sortable: false,
                    value: 'is_active_on_mpp',
                },
                {
                    text: 'Proviens des liens ressources',
                    sortable: false,
                    value: 'from_resource_register',
                },
            ],

            result: [],
            delay: 350,
            clicks: 0,
            timer: null,

            selectFilterItems: [
                {text: 'Tous', value: null},
                {text: 'Oui', value: 1},
                {text: 'Non', value: 0},
            ],

            clubTiersFilters: [
                {text: 'Peu importe', value: null},
                {text: 'Pas abonné', value: 0},
                {text: 'Tier 1', value: 1},
                {text: 'Tier 2', value: 2},
                {text: 'Tier 3', value: 3},
            ],

            clubPlatformItems: [
                {text: 'MyGuitare', value: 'MYGUITARE'},
                {text: 'MyPianopop', value: 'MYPIANOPOP'},
            ],
            isExportLoading: false,
        }),

        computed: {
            users: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            filtersLocal: {
                get() {
                    return this.filters
                },
                set(value) {
                    this.$emit('update:filters', value)
                }
            },

            hasClickShowUser() {
                return !!(this.$listeners && this.$listeners['click:showUser'])
            },

            hasClickAdd() {
                return !!(this.$listeners && this.$listeners['createUser'])
            },
        },

        watch: {},

        methods: {
            createUser() {
                this.$emit('createUser', this.newUser)
            },

            exportArchivedUsers() {
                this.isExportLoading = true;
                const pagination = {
                    page: 1,
                    itemsPerPage: 9999999999999,
                    sortBy: [],
                    sortDesc: [],
                };

                UserLogic
                    .export(pagination, {is_archived: true})
                    .then(() => {
                        //this.removeAllConditions();
                        this.isExportLoading = false;
                    })
                    .catch((err) => {
                        console.error(err)
                    });
            }

        }
    };
</script>
